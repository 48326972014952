<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useToast } from '@/components/ui/toast/use-toast'
import { Input } from '@/components/ui/input'
import { getLogo } from '@/utils/logo'
import { checkExistedDetail, createNew } from '@/apis/link'
const UButton = defineAsyncComponent(
  () => import('@/components/ui/button/Button.vue'),
)

const tab = ref('personal')

const name = ref('')
const domain = ref('')

// const domainGenerate = computed(() => {
//   // OLD
//   // if (!name.value) {
//   //   return `${window.location.origin}/page/`
//   // } else if (domain.value) {
//   //   return `${window.location.origin}/page/${name.value}/${domain.value}`
//   // } else return `${window.location.origin}/page/${name.value}`
//   // NEW
//   const locationHref = window.location.href.replace('/page', '/l')
//   return [locationHref, useTrim(name.value), useTrim(domain.value)]
//     .filter(Boolean)
//     .join('/')
// })
const domainGenerate = ref('')
function generateLink() {
  const locationHref = window.location.href.replace('/page', '/l')
  let tempUri = [locationHref, useTrim(name.value), useTrim(domain.value)]
    .filter(Boolean)
    .join('/')
  domainGenerate.value = encodeURI(tempUri)
}
onMounted(() => {
  generateLink()
})
watch([name, domain], () => {
  generateLink()
})

// ========= HORIZONTAL SCROLL HANDLER ===============

// define scroll container
const scrollContainer = ref<HTMLElement | null>(null)
// scroll horizontal event
const wheel = (e: WheelEvent) => {
  e.preventDefault()
  // the bigger deltaY is, the faster scroll is
  if (scrollContainer.value) scrollContainer.value.scrollLeft += e.deltaY * 0.2
}

// ============ DO CAPTURE SCREEN  ===========
const { generateCanvas, logo, cvImage } = useCaptureLogo()

watchDebounced(
  domain,
  () => {
    if (domain.value) logo.value = getLogo(domain.value, 200)
    else generateCanvas()
  },
  {
    debounce: 500,
  },
)

watch(logo, () => {
  // Having logo -> capture screen, then see logo  (on screen captured)
  // No domain -> capture screen, then remove logo (on screen captured)
  if (logo.value) generateCanvas()
})

// ========= CREATE LINK ===============
const { page } = useAccessPage()
const { account } = storeToRefs(useUserStore())
const { mutate: mutateWithCreated, onDone: onDoneWithCreated } = createNew({
  page_name: page.value?.attributes.Title as string,
  pageId: page.value?.id as string,
  passcode: '',
  recipient_domain: domain.value,
  recipient_name: name.value,
  thumbnail: cvImage.value as string,
})
const { refetch: refetchWithExisted, result: resultWithExisted } =
  checkExistedDetail({
    pageId: page.value?.id as string,
    recipient_domain: domain.value,
    recipient_name: name.value,
  })
const isExistedDetail = computed(
  () => !!resultWithExisted.value?.links?.data?.length,
)
// ========= DO COPY LINK ===============
const { copy, isSupported } = useClipboard()
const { toast } = useToast()

const callbackCopyDomain = async (linkId: string) => {
  let url = `${window.location.origin}/l/${linkId}/${name.value}/${domain.value}`
  domainGenerate.value = encodeURI(url)
  await copy(encodeURI(url))
  toast({
    class: 'p-4 pr-10 w-max ml-auto',
    title: 'Copied successfully !',
    variant: 'default',
  })
}
const doCopyDomain = async () => {
  if (isSupported.value) {
    await refetchWithExisted({
      pageId: page.value?.id as string,
      recipient_domain: domain.value,
      recipient_name: name.value,
    })

    if (!isExistedDetail.value) {
      mutateWithCreated({
        page_name: page.value?.attributes.Title as string,
        pageId: page.value?.id as string,
        passcode: '',
        recipient_domain: domain.value,
        recipient_name: name.value,
        thumbnail: cvImage.value as string,
        publishedAt: new Date(),
        userId: account.value!.id,
      })
    } else {
      const linkId = resultWithExisted.value?.links?.data?.[0]?.id
      callbackCopyDomain(linkId || '')
    }
  }
}

onDoneWithCreated((result) => {
  const linkId = result?.data?.createLink?.data?.id
  callbackCopyDomain(linkId || '')
})
</script>

<template lang="pug">
div
  .pop-header.flex.select-none.border-b(class='border-[#e2e8f0]')
    .px-3.py-2.cursor-pointer(@click='tab = "personal"') 
      |
      span(
        :class='["pb-2.5 text-[#94A3B8] font-semibold", { "text-black border-b-2 border-black": tab === "personal" }]'
      ) Personalized link
    .px-3.py-2.cursor-pointer(@click='tab = "sequence"')
      span(
        :class='["pb-2.5 text-[#94A3B8] font-semibold", { "text-black border-b-2 border-black": tab === "sequence" }]'
      ) Add to sequence
  .pop-content.px-4.mt-5
    .grid.grid-cols-2.gap-4
      // Recipient name
      .flex.flex-col
        label.font-medium.text-sm(for='name') Recipient name
        Input#name.mt-2.text-sm.transition-all(
          v-model='name'
          name='name'
          placeholder='Michael (or leave blank)'
          class='focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:shadow-[inset_0_0_0_1px_#8A2BE2]'
        )
      // Recipient domain
      .flex.flex-col
        label.font-medium.text-sm(for='domain') Recipient domain
        Input#domain.mt-2.text-sm.transition-all(
          v-model='domain'
          name='domain'
          placeholder='dundermifflin.com'
          class='focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:shadow-[inset_0_0_0_1px_#8A2BE2]'
        )
    .mt-4.rounded-md.flex.justify-between(class='bg-[#F1F5F9]')
      .flex.items-center.pl-3.overflow-auto.scrollbar--small.text-sm.select-none(
        class='max-w-[320px]'
        @wheel='wheel'
        ref='scrollContainer'
      ) {{ domainGenerate }}
      UButton.text-black.gap-2.w-max.m-1.transition-all.bg-white(
        class='hover:bg-white active:scale-95'
        @click='doCopyDomain'
      )
        .font-semibold.text-xs Copy link
    .mt-4.flex(v-if='domain')
      CaptureImage
      .ml-2
        UButton.bg-white.transition-all(
          variant='outline'
          class='active:scale-95 hover:bg-white'
          @click=''
        )
          Icon.w-4.h-3.mr-4(name='logos:google-gmail') 
          span.font-semibold Copy for Email
</template>

<style scoped></style>
